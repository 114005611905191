import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

interface AuthProps {
    onPinInput: (pin: string) => void;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    notice: {
        marginTop: '2rem',
        textAlign: 'center',
    },
}));

export const Auth = (props: AuthProps) => {
    const [pin, setPin] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const classes = useStyles();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.currentTarget.value;
        setPin(input);
        if (input.length === 5) props.onPinInput(input);
    };

    useEffect(() => {
        if (inputRef && inputRef.current) inputRef?.current?.focus();
    }, [inputRef]);

    return (
        <div className={classes.container}>
            <TextField
                style={{
                    width: '100%',
                }}
                variant="outlined"
                type="password"
                inputRef={inputRef}
                label="PIN"
                value={pin}
                onChange={onChange}
                inputProps={{ inputMode: 'numeric' }}
            />
            <p className={classes.notice}>Zur Authentifizierung bitte die PIN eingeben</p>
        </div>
    );
};
