import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FirebaseContext } from '../Firebase';
import { Loading } from '../Loading';
import { Expense } from '../../types';

interface StatusProps {
    render: boolean;
}

interface Aggregation {
    [key: string]: number;
}

interface Status {
    who: 'Jenni' | 'Markus';
    sum: number;
}

const useStyles = makeStyles((theme) => ({
    statusContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statusWho: {
        flex: 1,
        color: '#368726',
        fontSize: '1.5rem',
        fontWeight: 700,
    },
    statusSum: {
        flex: 2,
        fontSize: '2.5rem',
        fontWeight: 700,
    },
    extraText: {
        textAlign: 'center',
    },
}));

export const Status = (props: StatusProps) => {
    const fb = useContext(FirebaseContext);
    const [status, setStatus] = useState<Status | null>(null);
    const classes = useStyles();

    // fetch data on mount and whenever a rerender is triggered via props
    useEffect(() => {
        /**
         * Fetches all items from Firebase and calculates the new status (who has paid more)
         */
        const fetchData = async (): Promise<void> => {
            const response = await fb?.db.collection('expenses').get();
            if (!response) return undefined;

            const aggregation = response.docs.reduce(
                (acc, doc) => {
                    const dataset: Expense = doc.data() as Expense;
                    acc[dataset.who.toLowerCase()] += dataset.sum;
                    return acc;
                },
                { markus: 0, jenni: 0 } as Aggregation
            );

            const markusMore = aggregation.markus >= aggregation.jenni;

            setStatus({
                who: markusMore ? 'Markus' : 'Jenni',
                sum: markusMore ? aggregation.markus - aggregation.jenni : aggregation.jenni - aggregation.markus,
            });
        };

        fetchData();
    }, [fb, props.render]);

    return (
        <div className="container status">
            {!status && <Loading />}
            {status && (
                <div className={classes.statusContainer}>
                    <div className={classes.statusWho}>{status.who}</div>
                    <div className={classes.extraText}>hat mehr bezahlt:</div>
                    <div className={classes.statusSum}>+{(status.sum / 100).toFixed(2).replace('.', ',')}€</div>
                </div>
            )}
        </div>
    );
};
