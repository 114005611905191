import React from 'react';
import { Paper, Button, makeStyles, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ExpenseItem } from '../../types';
import { useExpenseList } from '../../hooks/useExpenseList';

interface ExpenseListProps {
    render: boolean;
    onDelete: (expenseId: string) => void;
}

const useStyles = makeStyles(() => ({
    loadMoreContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
    },
    tableCell: {
        padding: 5,
    },
}));

export const ExpenseList = (props: ExpenseListProps): JSX.Element => {
    const [items, moreItems, expenseQuery] = useExpenseList(props.render);
    const loadMore = () => expenseQuery();
    const classes = useStyles();

    return (
        <div className="container full-width">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Wer</TableCell>
                            <TableCell className={classes.tableCell}>Was</TableCell>
                            <TableCell className={classes.tableCell}>Wann</TableCell>
                            <TableCell className={classes.tableCell} align="right">
                                Summe
                            </TableCell>
                            <TableCell className={classes.tableCell}>Entf</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item: ExpenseItem, idx: number) => {
                            return (
                                <TableRow key={idx}>
                                    <TableCell className={classes.tableCell}>{item.who}</TableCell>
                                    <TableCell className={classes.tableCell}>{item.what}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {Intl.DateTimeFormat('de-DE').format(new Date(item.when.seconds * 1000))}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="right">
                                        {(item.sum / 100).toFixed(2).replace('.', ',')} €
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <IconButton component="span" onClick={() => props.onDelete(item.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {moreItems && (
                <div className={classes.loadMoreContainer}>
                    <Button color="primary" fullWidth onClick={() => loadMore()}>
                        Mehr laden ...
                    </Button>
                </div>
            )}
        </div>
    );
};
