import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { FirebaseContext } from '../Firebase';
import { Expense } from '../../types';
import { Status } from './Status';
import { NewExpense } from './NewExpense';
import { ExpenseList } from './ExpenseList';

export const MainScreen = () => {
    const [render, setRender] = useState(true);
    const fb = useContext(FirebaseContext);

    const newExpenseSubmit = async (obj: Expense) => {
        await fb?.db.collection('expenses').add(obj);
        toast('🤑 Ausgabe hinzugefügt!');
        setRender((state) => !state);
    };

    const deleteExpense = async (expenseId: string) => {
        await fb?.db.collection('expenses').doc(expenseId).delete();
        toast('Ausgabe gelöscht!');
        setRender((state) => !state);
    };

    return (
        <div className="main-screen-container">
            <Status render={render} />
            <NewExpense onSubmit={newExpenseSubmit} />
            <ExpenseList render={render} onDelete={deleteExpense} />
        </div>
    );
};
