import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import App from './App';
import { Firebase, FirebaseContext } from './components/Firebase';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#334a63',
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <FirebaseContext.Provider value={new Firebase()}>
                <CssBaseline />
                <App />
            </FirebaseContext.Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
