import React, { useState, useCallback, useEffect, useContext } from 'react';
import { withCookies, ReactCookieProps } from 'react-cookie';
import { FirebaseContext } from '../Firebase';
import { Auth } from '../Guard';
import { MainScreen } from '../MainScreen/MainScreen';
import { Loading } from '../Loading';

const useAuth = (authCookie: string | undefined) => {
    const fb = useContext(FirebaseContext);
    const auth = (pin: string) =>
        new Promise((resolve, reject) => {
            if (authCookie) return resolve(true);
            fb?.db
                .collection('auth')
                .doc('pin')
                .get()
                .then((doc) => {
                    if (doc.exists && pin === (doc.data()?.pinValue as string)) {
                        return resolve(true);
                    } else {
                        return resolve(false);
                    }
                });
        });
    return auth;
};

export const Guard = withCookies(
    (props: ReactCookieProps): JSX.Element => {
        const [isAuthed, setAuthed] = useState(false);
        const [isLoading, setLoading] = useState(true);
        const [pin, setPin] = useState('');

        const auth = useCallback(useAuth(props.cookies?.get('expauth')), []);
        useEffect(() => {
            auth(pin).then((result) => {
                if (result) {
                    props.cookies?.set('expauth', 'authed', { expires: new Date() });
                    setAuthed(true);
                } else {
                    setAuthed(false);
                }
                setLoading(false);
            });
        }, [auth, pin, props.cookies]);

        const onPinInput = (newPin: string) => setPin(newPin);

        if (isLoading) return <Loading />;
        return isAuthed ? <MainScreen /> : <Auth onPinInput={onPinInput} />;
    }
);
