import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Guard } from './components/Guard';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
    return (
        <div className="screenContainer">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                closeButton={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
            />
            <Guard />
        </div>
    );
}

export default App;
