import { useState, useEffect, useCallback, useContext } from 'react';
import { ExpenseResponse, ExpenseItem } from '../types';
import { firestore } from 'firebase';
import { FirebaseContext } from '../components/Firebase';

/**
 * Exposes methods and data to read/interact with the expense list via Firebase
 *
 * @param render - Gets passed doen from the main screen to trigger a rerender
 * @returns - An array comprising:
 *  - items: An array of expense items that have been loaded
 *  - moreItems: A boolean indicating whether more items could be loaded
 *  - expenseQuery: A function to trigger the next item load from Firebase
 */
export const useExpenseList = (render: boolean) => {
    const [moreItems, setMoreItems] = useState(true);
    const [lastItemsRef, setLastItemsRef] = useState<firestore.QueryDocumentSnapshot<firestore.DocumentData> | null>(
        null
    );
    const [items, setItems] = useState<ExpenseItem[]>([]);
    const PER_PAGE = 10;
    const fb = useContext(FirebaseContext);

    /**
     * Executes the next query for expense items and updates the items state
     *
     * @param refresh - A boolean to determine whether the next load should be a refresh
     *                  or the next page of items
     */
    const expenseQuery = useCallback(
        async (refresh = false): Promise<void> => {
            let response;

            // if a reference to the last loaded item exists and no refresh was triggered,
            // load next batch of items starting after the last one loaded
            if (lastItemsRef && !refresh) {
                response = await fb?.db
                    .collection('expenses')
                    .orderBy('when', 'desc')
                    .startAfter(lastItemsRef)
                    .limit(PER_PAGE)
                    .get();
            } else {
                response = await fb?.db.collection('expenses').orderBy('when', 'desc').limit(PER_PAGE).get();
            }

            // set reference to last loaded item if the respone returned data, also set
            // boolean indicating more items (currently determined when the length of the
            // response array is equal to the requested item count, this can lead to a false
            // positive when both numbers are accidentally equal without anything after the current
            // reference)
            if (response && response.docs.length > 0) {
                setLastItemsRef(response.docs[response.docs.length - 1]);
                response.docs.length === PER_PAGE ? setMoreItems(true) : setMoreItems(false);
            } else {
                setMoreItems(false);
            }

            // set state depending in whether a refresh or another load was triggered
            let newItems: ExpenseItem[] = [];
            response?.forEach((dataset) => {
                newItems.push({
                    ...(dataset.data() as ExpenseResponse),
                    id: dataset.id,
                });
            });
            setItems((items) => (refresh ? newItems : [...items, ...newItems]));
        },
        [fb, lastItemsRef]
    );

    // immediately execute the initial load
    useEffect((): void => {
        expenseQuery(true);
    }, [render]);

    return [items, moreItems, expenseQuery] as const;
};
