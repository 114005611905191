import React, { useState, ComponentType, ChangeEvent } from 'react';
import { TextField, Button, makeStyles } from '@material-ui/core';
import { Expense } from '../../types';

interface NewExpenseProps {
    onSubmit: (obj: Expense) => void;
}

interface Errors {
    sum?: string;
    what?: string;
}

const useStyles = makeStyles(() => ({
    error: {
        fontSize: '0.75rem',
        color: 'red',
        marginBottom: '0.25rem',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem',
    },
    rowAlign: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
    },
    sameHeight: {
        height: '2.5rem',
    },
}));

const getStringFromDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const NewExpense = ((props: NewExpenseProps) => {
    const [sum, setSum] = useState('');
    const [who, setWho] = useState(true);
    const [what, setWhat] = useState('');
    const [when, setWhen] = useState<Date | null>(null);
    const [errors, setErrors] = useState<Errors>({});
    const classes = useStyles();

    const onSumChange = (event: ChangeEvent<HTMLInputElement>) => setSum(event.currentTarget.value);
    const onWhatChange = (event: ChangeEvent<HTMLInputElement>) => setWhat(event.currentTarget.value);
    const onWhenChange = (event: ChangeEvent<HTMLInputElement>) => setWhen(new Date(event.currentTarget.value));

    const addError = (field: keyof Errors, msg: string) => setErrors((err) => ({ ...err, [field]: msg }));

    const handleSubmit = () => {
        setErrors({});
        let hasErrors = false;
        // validate entered sum and format as cents integer
        const sumCents = Math.floor(parseFloat(sum.replace(',', '.')) * 100);

        if (what.length === 0) {
            addError('what', 'Beschreibung fehlt');
            hasErrors = true;
        }

        if (Number.isNaN(sumCents)) {
            addError('sum', 'Kein gültiger Betrag');
            hasErrors = true;
        }

        if (hasErrors) return false;

        const whoName = who ? 'Markus' : 'Jenni';

        // submit form and reset inputs
        props.onSubmit({
            sum: sumCents,
            who: whoName,
            what,
            when: when || new Date(),
        });
        setSum('');
        setWhat('');
        setWho(true);
        setWhen(null);
    };

    return (
        <div className="container new">
            <div className={classes.formContainer}>
                <div>
                    <TextField fullWidth variant="outlined" value={sum} onChange={onSumChange} label="Summe (in €)" />
                    <p className={classes.error}>{errors.sum ? errors.sum : '\u00A0'}</p>
                </div>

                <div>
                    <TextField fullWidth variant="outlined" value={what} onChange={onWhatChange} label="Beschreibung" />
                    <p className={classes.error}>{errors.what ? errors.what : '\u00A0'}</p>
                </div>

                <div className={classes.rowAlign}>
                    <Button
                        variant="outlined"
                        color={who ? 'primary' : 'secondary'}
                        onClick={() => setWho((currentWho) => !currentWho)}
                        className={classes.sameHeight}
                    >
                        {who ? 'Markus' : 'Jenni'} zahlt
                    </Button>
                    <TextField
                        variant="outlined"
                        type="date"
                        defaultValue={(when && getStringFromDate(when)) || getStringFromDate(new Date())}
                        onChange={onWhenChange}
                        className={classes.sameHeight}
                        size="small"
                    />
                </div>

                <div>
                    <Button color="primary" fullWidth variant="contained" onClick={handleSubmit}>
                        Eintragen
                    </Button>
                </div>
            </div>
        </div>
    );
}) as ComponentType<NewExpenseProps>;
